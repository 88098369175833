import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Import your Firebase config

const UserList = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      
      const usersList = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setUsers(usersList);
    };

    fetchUsers();
  }, []);

  return (
    <div>
      <h2>User List</h2>
      <ul>
        {users.map(user => (
          <li key={user.id}>
            <details>
              <summary>{user.fullname}</summary>
              <p>UID: {user.id}</p>
              <p>Age: {user.age}</p>
              {user.organizations.length > 0 && (//Only shows organizations and roles if they have any assigned 
                <details>
                  <summary>Organizations</summary>
                  <ul>
                    {user.organizations.map(org => (
                      <li key={org.id}>{org.name}</li>
                    ))}
                  </ul>
                </details>
              )}
              {user.roles.length > 0 && (
                <details>
                  <summary>Roles</summary>
                  <ul>
                    {user.roles.map(role => (
                      <li key={role.id}>{role.name}</li>
                    ))}
                  </ul>
                </details>
              )}
            </details>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserList;