import React, { useEffect } from 'react';
import { signInWithRedirect } from 'firebase/auth';
import { useAuth } from './PlatformAuth';
import { auth } from '../firebaseConfig';
import { OAuthProvider } from 'firebase/auth';

const LinkedinAuth = () => {
  const linkedinClientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;

  const { user, loading, error, handleSignIn, handleSignOut } = useAuth(() => {
    const linkedinProvider = new OAuthProvider('linkedin.com');
    linkedinProvider.setCustomParameters({
      'client_id': linkedinClientId,
    });

    return signInWithRedirect(auth, linkedinProvider);
  });

  useEffect(() => {
    handleSignIn();
  }, [handleSignIn]);

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {user ? (
        <div>
          <p>Welcome, {user.displayName}!</p>
          <button onClick={handleSignOut}>Sign out</button>
        </div>
      ) : null}
    </div>
  );
};

export default LinkedinAuth;