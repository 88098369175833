import { Link } from "react-router-dom";

export const MyOrganizations = () => {
  return (
    <div className="MyOrganizations">
      <h1>THESE ARE MY Organizations</h1>
      <Link to="/organization">One of my Organizations</Link>
      <br />
      <Link to="/createorganization">Create Organization</Link>
    </div>
  );
};