//import { getAuth } from "firebase/auth";
import { auth as firebaseAuth } from '../firebaseConfig';
import { useState, useEffect } from 'react';

export const useAuth = (provider, providerName) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSignIn = async () => {
    try {
      console.log(`1`);
      setLoading(true);
      console.log(`2`);
      //const auth = getAuth(); // Create a separate auth object for each platform
      console.log(`3`);
      const response = await provider();
      console.log(`4`);
      setUser(response.user);
      setError(null);
      console.log(`User logged in with ${providerName}:`, response.user);
    } catch (error) {
      console.error(`Error logging in with ${providerName}:`, error);
      setError(`Failed to sign in with ${providerName}. ` + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      setLoading(true);
      await firebaseAuth.signOut(); // Use firebaseAuth instead of auth
      setUser(null);
      setError(null);
      console.log("User logged out:", user);
    } catch (error) {
      console.error(`Error signing out:`, error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return { user, loading, error, handleSignIn, handleSignOut };
};