import React, { useEffect } from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useAuth } from './PlatformAuth';
import { auth } from '../firebaseConfig';

const GoogleAuth = () => {
  const { user, loading, error, handleSignIn, handleSignOut } = useAuth(() => signInWithPopup(auth, new GoogleAuthProvider()), 'Google');

  useEffect(() => {
    handleSignIn();
  }, [handleSignIn]);

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {user ? (
        <div>
          <p>Welcome, {user.displayName}!</p>
          <button onClick={handleSignOut}>Sign out</button>
        </div>
      ) : (
        <button onClick={handleSignIn}>Sign in with Google</button>
      )}
    </div>
  );
};

export default GoogleAuth;