import React from 'react';
import { signInWithRedirect } from 'firebase/auth';
import { useAuth } from './PlatformAuth';
import { auth } from '../firebaseConfig';
import { OAuthProvider } from 'firebase/auth';

const DiscordAuth = () => {
  const discordAppId = process.env.REACT_APP_DISCORD_APP_ID; // Access Discord Application ID from environment variable

  const { user, loading, error, handleSignIn, handleSignOut } = useAuth(() => {
    const discordProvider = new OAuthProvider('discord.com');
    discordProvider.setCustomParameters({
      'client_id': discordAppId,
    });

    return signInWithRedirect(auth, discordProvider);
  });

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {user ? (
        <div>
          <p>Welcome, {user.displayName}!</p>
          <button onClick={handleSignOut}>Sign out</button>
        </div>
      ) : (
        <button onClick={handleSignIn}>Sign in with Discord</button>
      )}
    </div>
  );
};

export default DiscordAuth;

/*import React, { useEffect } from 'react';
import { signInWithRedirect } from 'firebase/auth';
import { useAuth } from './PlatformAuth';
import { auth } from '../firebaseConfig';
import { OAuthProvider } from 'firebase/auth';

const DiscordAuth = () => {
  const discordAppId = process.env.REACT_APP_DISCORD_APP_ID;

  const { user, loading, error, handleSignIn, handleSignOut } = useAuth(() => {
    const discordProvider = new OAuthProvider('discord.com');
    discordProvider.setCustomParameters({
      'client_id': discordAppId,
    });

    return signInWithRedirect(auth, discordProvider);
  });

  useEffect(() => {
    handleSignIn();
  }, [handleSignIn]);

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {user ? (
        <div>
          <p>Welcome, {user.displayName}!</p>
          <button onClick={handleSignOut}>Sign out</button>
        </div>
      ) : null}
    </div>
  );
};

export default DiscordAuth;*/