import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useEffect, useState } from 'react';
import { UserContext } from '../auth/UserContext';

const CheckRole = ({ role }) => {
  const { currentUser } = UserContext(UserContext);
  const [hasRole, setHasRole] = useState(false);

  useEffect(() => {
    const checkRole = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          setHasRole(userData.role === role);
        }
      }
    };

    checkRole();
  }, [currentUser, role]);

  return hasRole;
};

export default CheckRole;