import { Link } from "react-router-dom";
import googleIcon from '../images/google.png';
import twitterIcon from '../images/twitter.png';
import facebookIcon from '../images/facebook.png';
import instagramIcon from '../images/instagram.png';
import discordIcon from '../images/discord.png';
import linkedinIcon from '../images/linkedin.png';
import tiktokIcon from '../images/tiktok.png';

const AuthNavbar = () => {
  return (
    <div>
      <Link to="/googleauth">
        <img src={googleIcon} alt="Google" style={{ height: '24px', width: '24px' }} />
      </Link>
      <Link to="/twitterauth">
        <img src={twitterIcon} alt="Twitter" style={{ height: '24px', width: '24px' }} />
      </Link>
      <Link to="/facebookauth">
        <img src={facebookIcon} alt="Facebook" style={{ height: '24px', width: '24px' }} />
      </Link>

      <Link to="/instagramauth">
        <img src={instagramIcon} alt="Instagram" style={{ height: '24px', width: '24px' }} />
      </Link>

      <Link to="/discordauth">
        <img src={discordIcon} alt="Discord" style={{ height: '24px', width: '24px' }} />
      </Link>
      <Link to="/linkedinauth">
        <img src={linkedinIcon} alt="LinkedIn" style={{ height: '24px', width: '24px' }} />
      </Link>
      <Link to="/tiktokauth">
        <img src={tiktokIcon} alt="Tiktok" style={{ height: '24px', width: '24px' }} />
      </Link>
    </div>
  );
};

export default AuthNavbar;