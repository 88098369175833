import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../auth/UserContext';

const Navbar = () => {
  const { currentUser } = useContext(UserContext);

  return (
    <div>
      <Link to="/"> Home </Link>
      <Link to="/systemfirebaseauth"> Login/Logout </Link>

      {!currentUser && <Link to="/createowner"> Register </Link>}

      <Link to="/mygroups"> My workspaces and organizations </Link>
      <Link to="/organizationmanagement"> Organization management </Link>
    </div>
  );
};

export default Navbar;