import React, { useState, useContext } from 'react';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { auth } from '../firebaseConfig';
import { UserContext } from './UserContext';

const SystemFirebaseAuth = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log("1");
      console.log(userCredential);
      const user = userCredential.user;
      console.log("2");
      console.log(userCredential.user);
      const userDocRef = doc(getFirestore(), 'users', user.uid);
      console.log("3");
      console.log(userDocRef);
      const userDocSnapshot = await getDoc(userDocRef);
      console.log("4");
      console.log(userDocSnapshot);
      if (userDocSnapshot.exists()) {
        setCurrentUser(userDocSnapshot.data());
        console.log("5");
        console.log(user);
      } else {
        console.log('User document does not exist');
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    }
  };

  const onLogout = () => {
    signOut(auth)
      .then(() => {
        // Clear the current user using the setCurrentUser function
        setCurrentUser(null);
        console.log('User signed out successfully');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  return (
    <div>
      <h2>{currentUser ? `Welcome, ${currentUser.email}` : 'Login'}</h2>
      <form onSubmit={onLogin}>
        {currentUser ? (
          <button type="button" onClick={onLogout}>Logout</button>
        ) : (
          <div>
            <div>
              <label>Email:</label>
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="current-email" />
            </div>
            <div>
              <label>Password:</label>
              <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="current-password" />
            </div>
            <button type="submit">Login</button>
          </div>
        )}
      </form>
    </div>
  );
};

export default SystemFirebaseAuth;