import React, { useEffect } from 'react';
import { signInWithRedirect } from 'firebase/auth';
import { useAuth } from './PlatformAuth';
import { auth } from '../firebaseConfig';
import { OAuthProvider } from 'firebase/auth';

const InstagramAuth = () => {
  const instagramClientId = process.env.REACT_APP_INSTAGRAM_CLIENT_ID;

  const { user, loading, error, handleSignIn, handleSignOut } = useAuth(() => {
    const instagramProvider = new OAuthProvider('instagram.com');
    instagramProvider.setCustomParameters({
      'client_id': instagramClientId,
    });

    return signInWithRedirect(auth, instagramProvider);
  });

  useEffect(() => {
    handleSignIn();
  }, [handleSignIn]);

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {user ? (
        <div>
          <p>Welcome, {user.displayName}!</p>
          <button onClick={handleSignOut}>Sign out</button>
        </div>
      ) : null}
    </div>
  );
};

export default InstagramAuth;