import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Navbar from "./navbars/Navbar";
import AuthNavbar from "./navbars/AuthNavbar";

import OrganizationList from "./dataManagement/OrganizationsList";
import WorkspaceList from "./dataManagement/WorkspacesList";
import UserList from "./dataManagement/UsersList";

import MyGroups from "./dataManagement/MyGroups";

import CreateOwner from "./dataManagement/CreateOwner";
import CreateGroups from "./dataManagement/CreateGroups";
import OrganizationManagement from "./dataManagement/OrganizationManagement";

import SystemFirebaseAuth from "./auth/SystemFirebaseAuth";
import GoogleAuth  from "./auth/GoogleAuth";
import FacebookAuth from "./auth/FacebookAuth";
import TwitterAuth from "./auth/TwitterAuth";
import InstagramAuth from "./auth/InstagramAuth";
import DiscordAuth from "./auth/DiscordAuth";
import LinkedinAuth from "./auth/LinkedinAuth";
import TiktokAuth from "./auth/TiktokAuth";

import { UserProvider } from './auth/UserContext';

import { MyOrganizations } from "./organizations/MyOrganizations";
import { Organization } from "./organizations/Organization";
import { MyWorkspaces } from "./organizations/MyWorkspaces";
import { Workspace } from "./organizations/Workspace";

function App() {
  return (
    <div className="App">
      <UserProvider>
      <Router>
        <Navbar />
        <AuthNavbar />
        <Routes>
          <Route path="/" element={<Home />} />

          {/* For admins and devs */}
          <Route path="/organizationlist" element={<OrganizationList />} />
          <Route path="/workspacelist" element={<WorkspaceList />} />
          <Route path="/userlist" element={<UserList />} />

          {/* For users */}
          <Route path="/mygroups" element={<MyGroups />} />

          <Route path="/createowner" element={<CreateOwner />} />
          <Route path="/creategroups" element={<CreateGroups />} />
          <Route path="/organizationmanagement" element={<OrganizationManagement />} />
          
          <Route path="/systemfirebaseauth" element={<SystemFirebaseAuth />} />

          <Route path="/googleauth" element={<GoogleAuth />} />
          <Route path="/twitterauth" element={<TwitterAuth />} />
          <Route path="/facebookauth" element={<FacebookAuth />} />
          
          <Route path="/instagramauth" element={<InstagramAuth />} />

          <Route path="/discordauth" element={<DiscordAuth />} />
          <Route path="/linkedinauth" element={<LinkedinAuth />} />
          <Route path="/tiktokauth" element={<TiktokAuth />} />

          {/* Being replaced */}
          <Route path="/myorganizations" element={<MyOrganizations />} />
          <Route path="/organization" element={<Organization />} />
          <Route path="/myworkspaces" element={<MyWorkspaces />} />
          <Route path="/workspace" element={<Workspace />} />
          <Route path="*" element={<h1> PAGE NOT FOUND</h1>} />
        </Routes>
      </Router>
      </UserProvider>
    </div>
    //make sure the above redirects through the search bar are user sign in capped
  );
}

export default App;