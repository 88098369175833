import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Import your Firebase config

const OrganizationList = () => {
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const orgCollection = collection(db, 'organizations');
      const orgSnapshot = await getDocs(orgCollection);

      const orgList = orgSnapshot.docs.map(doc => ({
        id: doc.id,
        orgName: doc.data().name || 'N/A',
        users: doc.data().users || [],
        workspaces: doc.data().workspaces || []
      }));

      setOrganizations(orgList);
    };

    fetchOrganizations();
  }, []);

  return (
    <div>
      <h2>Organization List</h2>
      <ul>
        {organizations.map(org => (
          <li key={org.id}>
            <p>ID: {org.id}</p>
            <p>Name: {org.orgName}</p>
            {org.users.length > 0 && (
              <details>
                <summary>Users</summary>
                <ul>
                  {org.users.map(user => (
                    <li key={user.id}>{user.name}</li>
                  ))}
                </ul>
              </details>
            )}
            {org.workspaces.length > 0 && (
              <details>
                <summary>Workspaces</summary>
                <ul>
                  {org.workspaces.map(workspace => (
                    <li key={workspace.id}>{workspace.name}</li>
                  ))}
                </ul>
              </details>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrganizationList;