import React from 'react';
import { signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import { useAuth } from './PlatformAuth';
import { auth } from '../firebaseConfig';

const FacebookAuth = () => {
  const { user, loading, error, handleSignIn, handleSignOut } = useAuth(() => signInWithPopup(auth, new FacebookAuthProvider()), 'Facebook');

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {user ? (
        <div>
          <p>Welcome, {user.displayName}!</p>
          <button onClick={handleSignOut}>Sign out</button>
        </div>
      ) : (
        <button onClick={handleSignIn}>Sign in with Facebook</button>
      )}
    </div>
  );
}

export default FacebookAuth;
/*import React, { useEffect } from 'react';
import { signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
import { useAuth } from './PlatformAuth';
import { auth } from '../firebaseConfig';

const FacebookAuth = () => {
  const { user, loading, error, handleSignIn, handleSignOut } = useAuth(() => signInWithPopup(auth, new FacebookAuthProvider()), 'Facebook');

  useEffect(() => {
    handleSignIn();
  }, [handleSignIn]);

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {user ? (
        <div>
          <p>Welcome, {user.displayName}!</p>
          <button onClick={handleSignOut}>Sign out</button>
        </div>
      ) : (
        <button onClick={handleSignIn}>Sign in with Facebook</button>
      )}
    </div>
  );
};

export default FacebookAuth;*/