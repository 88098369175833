import React, { useEffect } from 'react';
import { signInWithRedirect } from 'firebase/auth';
import { useAuth } from './PlatformAuth';
import { auth } from '../firebaseConfig';
import { OAuthProvider } from 'firebase/auth';

const TiktokAuth = () => {
  const tiktokClientId = process.env.REACT_APP_TIKTOK_CLIENT_ID;

  const { user, loading, error, handleSignIn, handleSignOut } = useAuth(() => {
    const tiktokProvider = new OAuthProvider('tiktok.com');
    tiktokProvider.setCustomParameters({
      'client_id': tiktokClientId,
    });

    return signInWithRedirect(auth, tiktokProvider);
  });

  useEffect(() => {
    handleSignIn();
  }, [handleSignIn]);

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {user ? (
        <div>
          <p>Welcome, {user.displayName}!</p>
          <button onClick={handleSignOut}>Sign out</button>
        </div>
      ) : null}
    </div>
  );
};

export default TiktokAuth;