import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Import your Firebase config

const WorkspaceList = () => {
  const [workspaces, setWorkspaces] = useState([]);

  useEffect(() => {
    const fetchWorkspaces = async () => {
      const workspaceCollection = collection(db, 'workspaces');
      const workspaceSnapshot = await getDocs(workspaceCollection);

      const workspaceList = workspaceSnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name || 'N/A',
        organization: doc.data().organization || 'N/A',
        users: doc.data().users || []
      }));

      setWorkspaces(workspaceList);
    };

    fetchWorkspaces();
  }, []);

  return (
    <div>
      <h2>Workspace List</h2>
      <ul>
        {workspaces.map(workspace => (
          <li key={workspace.id}>
            <p>ID: {workspace.id}</p>
            <p>Name: {workspace.name}</p>
            <p>Organization: {workspace.organization}</p>
            {workspace.users.length > 0 && (
              <details>
                <summary>Users</summary>
                <ul>
                  {workspace.users.map(user => (
                    <li key={user.id}>{user.name}</li>
                  ))}
                </ul>
              </details>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WorkspaceList;