import React, { useState } from 'react';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';

function CreateGroups() {
  const [organizationData, setOrganizationData] = useState({
    name: '',
    users: '',
    workspaces: []
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setOrganizationData({
      ...organizationData,
      [e.target.name]: e.target.value
    });
  };

  const handleAddWorkspace = () => {
    setOrganizationData((prevData) => ({
      ...prevData,
      workspaces: [...prevData.workspaces, { name: '' }]
    }));
  };

  const handleWorkspaceChange = (index, value) => {
    setOrganizationData((prevData) => ({
      ...prevData,
      workspaces: prevData.workspaces.map((workspace, i) =>
        i === index ? { ...workspace, name: value } : workspace
      )
    }));
  };

  const handleRemoveWorkspace = (index) => {
    setOrganizationData((prevData) => ({
      ...prevData,
      workspaces: prevData.workspaces.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const orgCollection = collection(db, 'organizations');
    const orgDocRef = await addDoc(orgCollection, {
      name: organizationData.name,
      users: organizationData.users,
      workspaces: []
    });

    // Add individual workspaces to the workspaces collection
    const workspacePromises = organizationData.workspaces.map(async (workspace) => {
      const workspaceCollection = collection(db, 'workspaces');
      const workspaceDocRef = await addDoc(workspaceCollection, {
        organization: orgDocRef.id,
        users: [],
        name: workspace.name
      });

      return workspaceDocRef.id;
    });

    const workspaceUIDs = await Promise.all(workspacePromises);

    // Update the organization document with the workspace UIDs
    await updateDoc(doc(db, 'organizations', orgDocRef.id), {
      workspaces: workspaceUIDs
    });

    // Reset the form
    setOrganizationData({
      name: '',
      users: '',
      workspaces: []
    });

    //Navigate back to OrganizationManagement after completion
    navigate('/OrganizationManagement');
  };

  return (
    <div>
      <h1>Create Organization</h1>
      <form onSubmit={handleSubmit}>
        <h2>Name</h2>
        <input
          type="text"
          name="name"
          value={organizationData.name}
          onChange={handleChange}
        />

        <h2>Users</h2>
        <input
          type="text"
          name="users"
          value={organizationData.users}
          onChange={handleChange}
        />

        <h2>Workspaces</h2>
        {organizationData.workspaces.map((workspace, index) => (
          <div key={index}>
            <h3>Workspace Name</h3>
            <input
              type="text"
              name={`workspaces[${index}].name`}
              value={workspace.name}
              onChange={(e) => handleWorkspaceChange(index, e.target.value)}
            />
            <button type="button" onClick={() => handleRemoveWorkspace(index)}>
              Remove
            </button>
          </div>
        ))}

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button type="button" onClick={handleAddWorkspace}>
            Add Another Workspace
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
}

export default CreateGroups;