import React, { useState, useEffect, useContext } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, where, query } from 'firebase/firestore';
import { UserContext } from '../auth/UserContext';

const MyGroups = () => {
  const { currentUser } = useContext(UserContext);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (currentUser) {
      const fetchUserData = async () => {
        try {
          const usersCollectionRef = collection(db, 'users');
          const queryRef = query(usersCollectionRef, where('uid', '==', currentUser.uid));
          const querySnapshot = await getDocs(queryRef);

          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.error('No user data found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      fetchUserData();
    }
  }, [currentUser]);

  return (
    <div>
      {currentUser && (
        <div>
          <p>Logged in user: {currentUser.uid}</p>
          {userData && (
            <div>
              <p>Workspaces: {userData.workspaces.join(', ')}</p>
              <p>Organizations: {userData.organizations.join(', ')}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MyGroups;