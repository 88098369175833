import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../auth/UserContext';
import CheckRole from './CheckRole'; // Import the CheckRole component

const OrganizationManagement = () => {
  const { currentUser } = useContext(UserContext);

  const isOwner = <CheckRole role="owner" />; // Use the CheckRole component to check if the user has the role of 'owner'

  if (!currentUser || !isOwner) {
    return null; // Render nothing if the user is not logged in or not an owner
  }

  return (
    <div>
      <h1>Organization Management</h1>
      <Link to="/creategroups">Create Groups</Link>
      {/* Add more links and components as needed */}
    </div>
  );
};

export default OrganizationManagement;
 //console.log("1+"+currentUser);
  //console.log("2+"+currentUser.role);