import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { db, auth } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function CreateOwner() {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    age: 0,
    password: '',
    confirmpassword: '',
    organizations: [],
    workspaces: [],
  });

  const [errors, setErrors] = useState({
    email: '',
    age: '',
    passwordmatch: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors({
      email: '',
      age: '',
      passwordmatch: '',
    });

    // Validation checks
    let isValid = true;
    if (!validateEmail(userData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Invalid email',
      }));
      isValid = false;
    }
    if (userData.age <= 17) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        age: 'Age must be at least 18',
      }));
      isValid = false;
    }
    if (userData.password !== userData.confirmpassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        passwordmatch: 'Passwords do not match',
      }));
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, userData.email, userData.password);
      const uid = userCredential.user.uid;
      await addDoc(collection(db, 'users'), {
        uid: uid,
        name: userData.name,
        age: userData.age,
        role: "owner",
        organizations: [],
        workspaces: [],
      });
      setUserData({ name: '', email: '', age: 0, password: '', confirmpassword: '' });
      
      // Sign the user in after registration
      await signInWithEmailAndPassword(auth, userData.email, userData.password);
      
      //
      //Automatically logging the user in after registration also means the setCurrentUser in userContext must be set here too
      //

      // Redirect to OrganizationManagement page
      navigate('/organization-management');
    } catch (error) {
      console.error('Error creating user: ', error);
    }
  };

  const validateEmail = (email) => {
    const emailval = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailval.test(email);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1>THIS IS THE Register PAGE</h1>
      <input type="text" name="name" placeholder="Name" value={userData.name} onChange={handleChange} autoComplete="username" />
      <input type="text" name="email" placeholder="Email" value={userData.email} onChange={handleChange} autoComplete="email" />
      <p className="error">{errors.email}</p>
      <input type="number" name="age" placeholder="Age" value={userData.age} onChange={handleChange} />
      <p className="error">{errors.age}</p>
      <input type="password" name="password" placeholder="Password" value={userData.password || ''} onChange={handleChange} autoComplete="new-password" />
      <input type="password" name="confirmpassword" placeholder="Confirm Password" value={userData.confirmpassword || ''} onChange={handleChange} autoComplete="new-password" />
      <p className="error">{errors.passwordmatch}</p>
      <button type="submit">Submit</button>
    </form>
  );
}

export default CreateOwner;